export enum CategoryReferenceLevel3 {
    // Beverage
    // Adult Beverages -> Beer & ciders
    BEER = 'Beer',
    BEER_MIXED_DRINK = 'Beer mixed-drink',
    CIDER = 'Cider',
    NON_ALCOHOLIC_BEERS = 'Alcohol-free Beers',

    // Adult Beverages -> Champagne & sparkling wine
    CAVA = 'Cava',
    CHAMPAGNE = 'Champagne',
    CHAMPAGNE_BASED_COCKTAIL = 'Champagne-Based cocktail',
    NON_ALCOHOLIC_SPARKLING_WINE = 'Non-Alcoholic Sparkling Wine',
    OTHER_SPARKLING_WINE = 'Other Sparkling Wine',
    PROSECCO_AND_ITALIAN_SPARKLING_WINE = 'Prosecco & Italian Sparkling Wine',

    // Adult Beverages -> Spirits
    ANISE_FLAVORED = 'Anise Flavored',
    BITTERS_AND_SPRAYS = 'Bitters & Sprays',
    COCKTAILS = 'Cocktails',
    COGNAC_AND_ARMAGNAC = 'Cognac & Armagnac',
    FORTIFIED_WINES_AND_DESSERT_WINES = 'Fortified Wines & Dessert Wines',
    GIN = 'Gin',
    LIQUEURS = 'Liqueurs',
    NON_ALCOHOLIC_SPIRIT = 'Non-alcoholic Spirits',
    OTHER_SPIRITS = 'Other Spirits',
    RTD_AND_HARD_SELTZERS = 'RTD & Hard-Seltzers',
    RUM = 'Rum',
    SAKE_SOJU_SHOCHU = 'Sake Soju Shochu',
    TEQUILA_AND_MEZCAL = 'Tequila & Mezcal',
    VERMOUTHS_AND_APERITIFS = 'Vermouths and Aperitifs',
    VODKA = 'Vodka',
    WHISKY = 'Whisky',

    // Adult Beverages -> Wine
    NON_ALCOHOLIC_WINE = 'Non-Alcoholic Wine',
    RED_WINE = 'Red Wine',
    ROSE_WINE = 'Rosé Wine',
    WHITE_WINE = 'White Wine',
    WINE_SPRITZER_AND_WINE_BASED_COCKTAILS = 'Wine Spritzer and Wine-Based Cocktail',

    // Hot Beverages - Tea
    BUBBLE_TEA = 'Bubble Tea',
    MATCHA = 'Matcha',
    TEA = 'Tea',
    INFUSION_OR_HERBAL_TEA = 'Infusion / Herbal tea',

    // Non-Alcoholic Beverages -> NARTD (Non-Alcoholic Ready To Drink)
    CARBONATED_SOFT_DRINK = 'CSD (Carbonated Soft Drinks)',
    COCO = 'Eau de Coco ou Lait de Coco',
    FRUIT_BASED = 'Fruit Base',
    FUNCTIONAL_DRINK = 'Functional Drink',
    JUICES_NECTARS = 'Juices & Nectars',
    MILK_BASED = 'Milk-Based',
    TEA_BASED = 'Tea-Based',

    // Non-Alcoholic Beverages -> Syrup
    SYRUP = 'Syrup',

    // Non-Alcoholic Beverages -> Waters
    SPARKLING_WATER = 'Sparkling Water',
    STILL_WATER = 'Still Water',
    WATER_PITCHER = 'Water Pitcher',

    // Food
    CAFE_GOURMAND = 'Café Gourmand',
    BAGEL = 'Bagel',
    BAGUETTE = 'Baguette',
    BRIOCHE = 'Brioche',
    FICELLE = 'Ficelle',
    PITA = 'Pita',
    TARTINE = 'Tartine',
    TOAST = 'Toast',
    VIENNOISERIES = 'Viennoiseries',
    BUCHE = 'Buche',
    CHEESECAKE = 'Cheesecake',
    CHOCOLATE = 'Chocolate',
    CREME_BRULEE = 'Crème Brulée',
    DONUT = 'Donut',
    ECLAIR = 'Eclair',
    PROFITEROLES = 'Profiteroles',
    TIRAMISU = 'Tiramisu',
    ASPARAGUS = 'Asparagus',
    BLINIS = 'Blinis',
    BURRATA = 'Burrata',
    CARPACCIO = 'Carpaccio',
    COLD_CUTS = 'Cold Cuts',
    CUCUMBER = 'Cucumber',
    EGGS = 'Eggs',
    FISH = 'Fish',
    FOIE_GRAS = 'Foie gras',
    GASPACHO = 'Gaspacho',
    PATE = 'Pâté',
    SALADS = 'Salads',
    TABOULE = 'Taboulé',
    TARTARES = 'Tartares',
    VEAL = 'Veal',
    MOUSSE = 'Mousse',
    PANNA_COTTA = 'Panna Cotta',
    CREPES = 'Crêpes',
    PANCAKES = 'Pancakes',
    WAFFLE = 'Waffle',
    BENEDICT = 'Benedict',
    OMELET = 'Omelet',
    SCRAMBLED_EGGS = 'Scrambled Eggs',
    ASIAN = 'Asian',
    FRENCH = 'French',
    ITALIAN = 'Italian',
    MEDITERRANEAN = 'Mediterranean',
    MEXICAN = 'Mexican',
    MIDDLE_EASTERN = 'Middle Eastern',
    NORTH_AFRICAN = 'North African',
    SPANISH = 'Spanish',
    LENTILS = 'Lentils',
    QUINOA = 'Quinoa',
    CALAMARI = 'Calamari',
    CROQUETTES = 'Croquettes',
    MOZZA_STICKS = 'Mozza Sticks',
    SAMOUSSA = 'Samoussa',
    SNAILS = 'Snails',
    SOUPS = 'Soups',
    BEEF = 'Beef',
    FROG_LEGS = 'Frog Legs',
    LAMB = 'Lamb',
    PORK = 'Pork',
    POULTRY = 'Poultry',
    POKE = 'Poké',
    PASTA = 'Pasta',
    RICE = 'Rice',
    QUICHE = 'Quiche',
    ALIGOT = 'Aligot',
    FRENCH_FRIES = 'French Fries',
    GRATIN = 'Gratin',
    MASHED_POTATOES = 'Mashed Potatoes',
    CHIPS = 'Chips',
    BAGNAT = 'Bagnat',
    CLUB = 'Club',
    CROQUE = 'Croque',
    EMPANADAS = 'Empanadas',
    HOT_DOG = 'Hot Dog',
    PANINI = 'Panini',
    WRAP = 'Wrap',
    CEVICHE = 'Ceviche',
    FISH_AND_CHIPS = 'Fish & Chips',
    SHELLFISH = 'Shellfish',
    AMUSE_BOUCHE = 'Amuse-Bouche',
    ANTIPASTI = 'Antipasti',
    BRUSCHETTAS = 'Bruschettas',
    DIPS = 'Dips',
    MEZZE = 'Mezze',
    TAPAS = 'Tapas',
    YOGURT = 'Yogurt',
    PLAT_DU_JOUR = 'Plat du Jour',
    ENTREE_DU_JOUR = 'Entrée du Jour',
    BAKLAVA = 'Baklava',
    CLAFOUTI = 'Clafouti',
    CRUMBLE = 'Crumble',
    FLAN = 'Flan',
    ILE_FLOTTANTE = 'Ile Flottante',
    MACARONS = 'Macarons',
    PAVLOVA = 'Pavlova',
    TATIN = 'Tatin',
    VACHERIN = 'Vacherin',
    CANDY = 'Candy',
    CHURROS = 'Churros',
    COOKIES = 'Cookies',
    MUFFINS = 'Muffins',
    AVOCADO = 'Avocado',
    BEANS = 'Beans',
    CELERI = 'Celeri',
    EGGPLANT = 'Eggplant',
    LEEK = 'Leek',
    MASHED = 'Mashed',
    MUSHROOM = 'Mushroom',
    PEPPER = 'Pepper',
    PUMPKIN = 'Pumpkin',
    TOMATOES = 'Tomatoes',
    ZUCCHINI = 'Zucchini',
    VEGAN_DISHES = 'Vegan Dishes',
    VEGETARIAN_DISHES = 'Vegetarian Dishes',
}

export const category3_options = Object.values(CategoryReferenceLevel3);
