import { Result } from "@mapbox/mapbox-gl-geocoder";
import _ from "lodash";
import log from "loglevel";
import { IntlShape } from "react-intl";
import { Dispatch } from "redux";
import { appIntl } from "../../Common/components/IntlGlobalProvider";
import { CatalogExtended } from "../../my-lemonade-library/model/catalogExtended/CatalogExtended";
import { Location, Table } from "../../my-lemonade-library/model/Location";
import { Order } from "../../my-lemonade-library/model/Order";
import { Customer } from "../../my-lemonade-library/src/authentications/models/Customer";
import Address from "../../my-lemonade-library/src/common/models/Address";
import Position from "../../my-lemonade-library/src/common/models/Position";
import { getFullAddressFromAddress } from "../../my-lemonade-library/src/customers/services/CustomerHelper";
import { DeliveryAddressTypeChoice } from "../../my-lemonade-library/src/delivery/models/DeliveryAddressTypeChoice";
import { addDeliveryZoneaAndCharge } from "../../orders/helpers/OrderHelpers";
import { default as orderActions } from "../../orders/redux/OrderActions";
import CheckedDeliveryZone from "../models/CheckedDeliveryZone";

/**
 * Updates the order.customer object with the address given. 
 * Also adds the delivery_zone and the charge associated to redux state.
 * @param address 
 * @param deliveryZone built after the API call, see DeliveryHelpers.ts > checkDeliveryAvailableAPI
 * @param intl 
 * @param dispatch 
 * @param order 
 * @param catalog 
 * @param location 
 */
export const setCustomerAndChargesFromAddress = (
    address: Address,
    deliveryZone: CheckedDeliveryZone,
    intl: IntlShape,
    dispatch: Dispatch<any>,
    order: Order,
    catalog: CatalogExtended,
    location: Location,
    table: Table,
    delivery_note: string | undefined,
    chosenDeliveryAddressType: DeliveryAddressTypeChoice | undefined
): void => {

    // TODO: group actions
    addDeliveryZoneaAndCharge(
        intl.formatMessage({ id: "delivery" }),
        deliveryZone.price ?? deliveryZone.default_delivery_fee,
        dispatch,
        catalog,
        deliveryZone,
        location,
        table
    );

    // We get the existing customer and will then merge the address into it. If no customer
    // in redux state, we create a new empty one.
    let newCustomer: Customer = order.customer ? _.cloneDeep(order.customer) : {};

    insertAddressIntoCustomer(address, newCustomer);
    if (!_.isNil(delivery_note)) {
        newCustomer.delivery_notes = delivery_note
    }
    if (chosenDeliveryAddressType) {
        newCustomer.delivery_address_type_choice = chosenDeliveryAddressType
    }
    dispatch(orderActions.setCustomerInfo(newCustomer, true));
    log.debug("dispatch customer done");
}

/**
 * Transform a Mapbox.Result into an Address object
 * @param result 
 * @returns 
 */
export const getAddressFromMapboxResult = (result: Result): Address => {

    const address: Address = {
        address_1: "",
        city: "",
        country: "",
        postal_code: "",
    }

    let streetName = result.text ? result.text : "";
    if (result.address) {
        streetName = `${result.address} ${streetName}`;
    }


    address.address_1 = streetName
        .replace(/-Iᵉʳ/g, " 1er")
        .replace(/-/g, " ")
        .trim();

    let postCode: string = "";
    let city: string = "";
    let country: string = "";

    if (result.context) {

        result.context.forEach((elem: any) => {

            if (elem.id && elem.id.includes("postcode")) {
                postCode = elem.text;
            }
            else if (elem.id && elem.id.includes("place")) {
                city = elem.text;
            }
            else if (elem.id && elem.id.includes("country")) {
                country = elem.short_code.toUpperCase();
            }
        });
    }

    address.postal_code = postCode;
    address.city = city;
    address.country = country;



    // Now the coordinates
    if (result.geometry.coordinates) {

        const position: Position = {
            latitude: result.geometry.coordinates[1],
            longitude: result.geometry.coordinates[0],
        }

        address.position = position;
    }

    return address;
}

/**
 * Gets an address and a customer object in parameters, and fills the
 * address fields of the customer, extracting them from the address given
 * @param address 
 * @param customer 
 */
export const insertAddressIntoCustomer = (address: Address, customer: Customer): void => {

    customer.address_1 = address.address_1;
    customer.address_2 = address.address_2;
    customer.postal_code = address.postal_code;
    customer.city = address.city;
    customer.country = address.country;

    if (address.position) {

        customer.latitude = address.position.latitude.toString();
        customer.longitude = address.position.longitude.toString();
    }

}

/**
 * What to display next to the "location" icon: a generic
 * text ("enter the new address") or the already entered address?
 * Also returns a boolean which indicates if it's an address or the placeHolder
 * @returns 
 */
export const getNewAddressPlaceHolder = (address?: Address | null): [string, boolean] => {

    let addressString: string | null = null;

    if (address) {
        addressString = getFullAddressFromAddress(address);
    }

    if (addressString) {
        return [addressString, true];
    }
    else {
        return [appIntl().formatMessage({ id: "customer_information_modal.delivery.placeholder" }), false];
    }
}
