import { Location, SupportedServiceType } from "../../../model/Location";
import { OrderChargeType } from "../../../model/Order";
import { DiscountType } from "../../discounts/models/OrderDiscount";
import { DeliverooConnectorParameters } from "../deliveroo/models/DeliverooConnectorParameters";
import { LightspeedParam } from "../lightspeed/models/LightspeedConnectorParams";
import { OneboxConnectorParameters } from "../onebox/models/OneboxConnectorParams";
import { OrchestraConnectorParams } from "../orchestra/models/OrchestraConnectorParams";
import { UntillConnectorParameters } from "../untill/models/UntillConnectorParameters";
import ConnectorCustomerInfoMode from "./ConnectorCustomerInfoMode";
import { ConnectorPaymentType } from "./ConnectorPaymentType";

export interface connectorParam {

    /**
     * Should the customer info be sent to the POS
     * If null, considered full mode
     */
    customer_info_mode?: ConnectorCustomerInfoMode;

    // Location template provided to create new location
    location_template?: Location

    service_type_ref?: ServiceTypeRef

    /**
    * These codes must refer to items sku in the POS dedicated to the order fields.
    * By default (when creating the location), they will be equal to the enum value.
    */
    order_charges_refs?: { [key in OrderChargeType]: string };
    order_discounts_refs?: { [key in DiscountType]: string };
    order_fields_refs?: { [key: string]: string };  // Example: {customer_notes: note}

    // When importing a location, fill this array if the list of payment_types is available
    payment_types?: ConnectorPaymentType[];

    // Set this with the reference of the payment method in the connector for My Lemonade payments.
    // WARNING: this setting overrides the payment_type refs set in My Lemonade Location when sending an order to the POS.
    // Default is getresto-payment for lightspeed
    payment_ref?: string;

    // The email used for the oauth. Is useful to retrieve the secret.
    // Only used by lightspeed for now
    email?: string;
}

export type ConnectorParametersType =
    carrePosParams
    | TillerParams
    | leo2Param
    | hubriseParam
    | deliverectParam
    | symbiozParam
    | RushourConnectorParameters
    | OneboxConnectorParameters
    | DeliverooConnectorParameters
    | KeytchensConnectorParameters
    | LightspeedParam
    | OrchestraConnectorParams
    | UntillConnectorParameters;

export interface ServiceTypeRef {
    [SupportedServiceType.VIEW]: string,
    [SupportedServiceType.EAT_IN]: string,
    [SupportedServiceType.COLLECTION]: string,
    [SupportedServiceType.DELIVERY]: string,
    [SupportedServiceType.CHECKOUT]: string,
}

//Params might be needed to send order
export interface carrePosParams extends connectorParam {
    id_restaurant: string;
    /**
     * @deprecated: use generic order_charges_refs instead
     */
    delivery_product_ref?: string;
}

export interface TillerParams extends connectorParam {
    restaurant_id: number;
}

export interface leo2Param extends connectorParam { }

export interface hubriseParam extends connectorParam {
    location_id: string;
    location_name?: string;
    catalog_id: string;
    catalog_name?: string;
    customer_list_id?: string;
    customer_list_name?: string;
}

export interface deliverectParam extends connectorParam {
    id_callback: string;
    channel_link_id: string;
    nom_menu: string;
}

export interface symbiozParam extends connectorParam {
    id_restaurant: string;
}

export const ONEBOX_BOXID_KEY = "boxId";
export const ONEBOX_BOXID_PARAM = `:${ONEBOX_BOXID_KEY}`;

export interface RushourConnectorParameters extends connectorParam {
    integration_id: string;
    app_id: string;
}

export interface KeytchensConnectorParameters extends connectorParam {
    restaurant_uuid: string;
    company_uuid: string
}
