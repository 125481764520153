export enum CategoryReferenceLevel2 {
    // Adult beverages
    BEER_CIDERS = 'Beers/Ciders',
    CHAMPAGNE_SPARKLING_WINE = 'Champagne & Sparkling Wine',
    SPIRITS = 'Spirits',
    WINE = 'Wine',

    // Non-alcoholic beverages
    COFFEE_BASED = 'Coffee-Based',
    MILK_BASED = 'Milk-Based',
    NON_ALCOHOLIC_READY_TO_DRINK = 'NARTD (Non-Alcoholic Ready To Drink)',
    PLANT_AND_NUT_BASED = 'Plant & Nut-Based Milk Alternatives',
    SQUEEZED_JUICES = 'Squeezed Juices',
    SYRUP_AND_FRUIT_CONCENTRATE = 'Syrup & Fruit Concentrate',
    TEA_BASED = 'Tea-Based',
    WATERS = 'Waters',

    // Hot beverages
    COFFEE = 'Coffee',
    GROG = 'Grog',
    TEA = 'Tea',

    // Food
    ASSORTED_DESSERTS = 'Assorted Desserts',
    BARBECUE = 'Barbecue',
    BEARNAISE = 'Bearnaise',
    BOLOGNESE = 'Bolognese',
    BREAD_AND_TOAST = 'Bread & Toast',
    BURGER = 'Burger',
    BUTTER = 'Butter',
    CAKES_AND_PASTRIES = 'Cakes & Pastries',
    CARAMEL = 'Caramel',
    CEREALS_AND_GRANOLA = 'Cereals & Granola',
    CHANTILLY = 'Chantilly',
    CHEESE = 'Cheese',
    CHIMICHURRI = 'Chimichurri',
    CHOCOLATE = 'Chocolate',
    COLD_STARTERS = 'Cold Starters',
    CONFECTIONERIES = 'Confectioneries',
    CREPES_PANCAKES_AND_WAFFLES = 'Crêpes, Pancakes & Waffles',
    CURRY = 'Curry',
    EGG_BASED_DISHES = 'Egg-Based Dishes',
    ETHNIC_REGIONAL_SPECIALTIES = 'Ethnic/Regional Specialties',
    FISH = 'Fish',
    FRUIT_BASED_DESSERTS = 'Fruit-Based Desserts',
    GARLIC = 'Garlic',
    GRAINS_AND_LEGUMES = 'Grains & Legumes',
    HERBS = 'Herbs',
    HONEY = 'Honey',
    HOT_STARTERS = 'Hot Starters',
    ICE_CREAM_AND_FROZEN_TREATS = 'Ice Cream & Frozen Treats',
    KETCHUP = 'Ketchup',
    MAYONNAISE = 'Mayonnaise',
    MEAT = 'Meat',
    MEAT_BASED_DISHES = 'Meat-Based Dishes',
    MUSHROOMS = 'Mushrooms',
    MUSTARD = 'Mustard',
    NAAN = 'Naan',
    OTHER_DISHES = 'Other Dishes',
    PASTA_RICE_BASED_DISHES = 'Pasta/Rice-Based Dishes',
    PEANUT = 'Peanut',
    PEPPER = 'Pepper',
    PIES_AND_TARTS = 'Pies & Tarts',
    PIZZA = 'Pizza',
    POTATOES = 'Potatoes',
    RICE = 'Rice',
    SALTED_SNACKS = 'Salted Snacks',
    SANDWICHES_AND_WRAPS = 'Sandwiches & Wraps',
    SEAFOOD_BASED_DISHES = 'Seafood-Based Dishes',
    SHARING_PLATTERS_AND_SMALL_BITES = 'Sharing Platters & Small Bites',
    SMOOTHIES_AND_YOGURTS = 'Smoothies & Yogurts',
    SOYA = 'Soya',
    SPECIALS = 'Specials',
    SPECIALTY_DESSERTS = 'Specialty Desserts',
    SWEET_SNACKS = 'Sweet Snacks',
    VEGETABLES = 'Vegetables',
    VEGETARIAN_VEGAN_DISHES = 'Vegetarian/Vegan Dishes',
    YOGURT = 'Yogurt',
}

// export const categoryReferenceLevel2Keywords: { [key in CategoryReferenceLevel2]: string[] } = {
//     [CategoryReferenceLevel2.BEER_CIDERS]: ["beer", "ciders"],
//     [CategoryReferenceLevel2.WINE]: ["wine"],
//     [CategoryReferenceLevel2.SPIRITS]: ["spirits"],
//     [CategoryReferenceLevel2.CHAMPAGNE_SPARKLING_WINE]: ["champagne", "sparkling wine"],
//     [CategoryReferenceLevel2.MILK_BASED]: ["milk"],
//     [CategoryReferenceLevel2.WATERS]: ["water"],
//     [CategoryReferenceLevel2.COFFEE_BASED]: ["coffee"],
//     [CategoryReferenceLevel2.SYRUP_AND_FRUIT_CONCENTRATE]: ["syrup", "fruit concentrate"],
//     [CategoryReferenceLevel2.NON_ALCOHOLIC_READY_TO_DRINK]: ["non alcoholic ready to drink", "nartd"],
//     [CategoryReferenceLevel2.SQUEEZED_JUICES]: ["squeezed juices"],
//     [CategoryReferenceLevel2.PLANT_AND_NUT_BASED]: ["plant", "nut"],
//     [CategoryReferenceLevel2.COFFEE]: ["coffee"],
//     [CategoryReferenceLevel2.TEA]: ["tea"],
//     [CategoryReferenceLevel2.HOT_MILK_BASED]: ["hot milk"],
// }

export const category2_options = Object.values(CategoryReferenceLevel2);
